// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-boletines-js": () => import("./../../../src/pages/boletines.js" /* webpackChunkName: "component---src-pages-boletines-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infografias-js": () => import("./../../../src/pages/infografias.js" /* webpackChunkName: "component---src-pages-infografias-js" */),
  "component---src-pages-intranet-js": () => import("./../../../src/pages/intranet.js" /* webpackChunkName: "component---src-pages-intranet-js" */),
  "component---src-pages-lineastrabajo-js": () => import("./../../../src/pages/lineastrabajo.js" /* webpackChunkName: "component---src-pages-lineastrabajo-js" */),
  "component---src-pages-miembros-js": () => import("./../../../src/pages/miembros.js" /* webpackChunkName: "component---src-pages-miembros-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-quienessomos-js": () => import("./../../../src/pages/quienessomos.js" /* webpackChunkName: "component---src-pages-quienessomos-js" */),
  "component---src-pages-resumenes-regulatorios-js": () => import("./../../../src/pages/resumenes-regulatorios.js" /* webpackChunkName: "component---src-pages-resumenes-regulatorios-js" */),
  "component---src-templates-boletines-boletin-boletin-js": () => import("./../../../src/templates/boletines/boletin/boletin.js" /* webpackChunkName: "component---src-templates-boletines-boletin-boletin-js" */),
  "component---src-templates-boletines-boletines-js": () => import("./../../../src/templates/boletines/boletines.js" /* webpackChunkName: "component---src-templates-boletines-boletines-js" */),
  "component---src-templates-comunicados-comunicado-comunicado-js": () => import("./../../../src/templates/comunicados/comunicado/comunicado.js" /* webpackChunkName: "component---src-templates-comunicados-comunicado-comunicado-js" */),
  "component---src-templates-comunicados-comunicados-js": () => import("./../../../src/templates/comunicados/comunicados.js" /* webpackChunkName: "component---src-templates-comunicados-comunicados-js" */),
  "component---src-templates-evento-evento-js": () => import("./../../../src/templates/evento/evento.js" /* webpackChunkName: "component---src-templates-evento-evento-js" */)
}

